.ThreeColumn {
  align-content: center;
  min-height: 100vh;
  padding: 200px 0;

  &__columns {
    display: block;
    justify-content: space-between;
    margin-top: 80px;
    text-align: center;

    @include breakpoint(s) {
      display: flex;
    }
  }

  &__column {
    align-items: center;
    display: flex;
    flex-direction: column;

    &:not(:last-of-type) {
      margin-bottom: 80px;

      @include breakpoint(s) {
        margin-bottom: 0;
        margin-right: 80px;
      }
    }

    &-image {
      height: 160px;
      margin-bottom: 16px;
      position: relative;
      width: 160px;
    }
  }

  &__button {
    margin: 96px auto 0;
  }
}