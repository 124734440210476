body {
  font-family: 'Manrope', sans-serif;
}

.bg {
  &--black {
    background: $color-black;
    color: $color-white;
  }

  &--green {
    background: $color-green;
    color: $color-white;
  }

  &--blue {
    background: $color-blue;
    color: $color-white;
  }

  &--white {
    background: $color-white;
  }
}

.color {
  &--black {
    color: $color-black;
  }

  &--white {
    color: $color-white;
  }
}

.overflow-hidden {
  overflow: hidden;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 94%;
  background-position-y: 8px;
}

ul {
  margin-left: 21px;
}