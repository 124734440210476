.display-1 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
  letter-spacing: -0.005em;

  @include breakpoint(s) {
    font-size: 42px;
    line-height: 54px;
  }

  @include breakpoint(l) {
    font-size: 48px;
    line-height: 65px;
  }

  @include breakpoint(xl) {
    font-size: 56px;
    line-height: 65px;
  }
}

.display-2 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 42px;

  @include breakpoint(s) {
    font-size: 36px;
    line-height: 48px;
  }

  @include breakpoint(l) {
    font-size: 40px;
    line-height: 54px;
  }

  @include breakpoint(xl) {
    font-size: 48px;
    line-height: 54px;
  }
}

.heading-1 {
  font-family: Manrope;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;

  @include breakpoint(s) {
    font-size: 24px;
    line-height: 34px;
  }

  @include breakpoint(l) {
    font-size: 28px;
    line-height: 38px;
  }

  @include breakpoint(xl) {
    font-size: 40px;
    line-height: 50px;
  }
}

.heading-2 {
  font-family: Manrope;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;

  @include breakpoint(s) {
    font-size: 20px;
    line-height: 38px;
  }

  @include breakpoint(l) {
    font-size: 22px;
    line-height: 32px;
  }

  @include breakpoint(xl) {
    font-size: 34px;
    line-height: 44px;
  }
}

.heading-3 {
  font-family: Manrope;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;

  @include breakpoint(l) {
    font-size: 18px;
    line-height: 25px;
  }

  @include breakpoint(xl) {
    font-size: 24px;
    line-height: 25px;
  }
}

.body {
  font-family: Manrope;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.01em;

  @include breakpoint(s) {
    font-size: 16px;
    line-height: 26px;
  }

  @include breakpoint(l) {
    font-size: 18px;
    line-height: 38px;
  }

  @include breakpoint(xl) {
    font-size: 24px;
    line-height: 38px;
  }
}

.body--bold {
  @extend .body;
  font-weight: 500;
}

.label {
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  @include breakpoint(l) {
    font-size: 14px;
    line-height: 18px;
  }
}

.input {
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 27px;
}