.button {
  color: $color-white;
  cursor: pointer;
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  @include breakpoint(s) {
    font-size: 18px;
    line-height: 24px;
  }

  @include breakpoint(l) {
    font-size: 20px;
    line-height: 27px;
  }

  &--primary {
    border: 2px solid $color-white;
    border-radius: 4px;
    box-shadow: $box-shadow-button;
    padding: 12px 40px;

    &:hover {
      background: $color-white;
      color: $color-almost-black;
    }
  }

  &--small {
    font-family: Manrope;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    padding: 8px 24px;
  
    @include breakpoint(l) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  &--blue {
    border-color: $color-blue;
    color: $color-blue;

    &:hover {
      background: $color-blue;
      color: $color-white;
    }
  }

  &--black {
    border-color: $color-almost-black;
    color: $color-almost-black;

    &.button--primary {
      &:hover {
        background: $color-almost-black;
        color: $color-white;
      }
    }
  }

  &--secondary {
    align-items: center;
    display: flex;
    font-weight: 400;

    svg {
      path {
        stroke: currentColor;
        stroke-width: 1px;
      }

      margin-left: 16px;
      width: 40.5px;
    }

    &:hover {
      font-weight: 600;
      
      svg {
        path {
          stroke-width: 2px;
        }
      }
    }
  }
}