.JobForm {
  color: $color-black;
  min-height: 100vh;
  padding: 64px 0;

  @include breakpoint(s) {
    padding: 90px 0;
  }

  ::placeholder {
    text-transform: capitalize;
  }

  &--submitted {
    &__container {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      min-height: 100vh;
    }

    &__tick {
      width: 128px;

      @include breakpoint(xs) {
        width: 200px;
      } 
    }
  }

  &__container {
    align-self: center;
  }

  &__heading {
    color: $color-white;
    margin-bottom: 48px;
  }

  &__card {
    border: 1px solid #EDEDED;
    box-sizing: border-box;
    box-shadow: $box-shadow-card;
    border-radius: 16px;
    padding: 48px 16px;

    @include breakpoint(xs) {
      padding: 48px 32px;
    }

    @include breakpoint(s) {
      padding: 48px 40px
    }
  }

  &__label {
    display: flex;
    flex-direction: column;

    input,
    select {
      border: 1px solid $color-gray;
      border-radius: 4px;
      margin-top: 8px;
      padding: 12px 24px;
    }

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }

  &__fieldset {
    display: contents;
  }

  &__button {
    display: flex;
    justify-content: center;
    margin-top: 32px;
  }

  &__time-date {
    border: 1px solid $color-gray;
    display: flex;
    border-radius: 4px;
    margin-top: 8px;
    padding: 12px 24px;

    input {
      border: none;

      &:first-of-type() {
        border-right: 1px solid $color-gray;
        padding-right: 8px;
        margin-right: 8px;

        @include breakpoint(xs) {
          padding-right: 16px;
          margin-right: 16px;
        }

        @include breakpoint(s) {
          padding-right: 24px;
          margin-right: 24px;
        }
      }
    }
  }

  &__radio-buttons {
    border: 1px solid $color-gray;
    display: flex;
    border-radius: 4px;
    margin-top: 8px;
    padding: 12px 24px;

    &--yes,
    &--no {
      align-items: center;
      display: flex;

      input {
        margin-top: 0;
      }
    }

    &--yes {
      align-items: center;
      margin-right: 32px;
    }
  }
}

.JobFormMini {

  &__heading {
    margin-bottom: 16px;
    text-align: center;
  }

  &__card {
    border: 1px solid #EDEDED;
    bottom: -80px;
    box-sizing: border-box;
    box-shadow: $box-shadow-card;
    border-radius: 16px;
    padding: 48px 40px;
    right: 200px;
  }

  &__label {
    display: flex;
    flex-direction: column;
    min-width: 300px;

    ::placeholder {
      text-transform: capitalize;
    }

    input {
      border: 1px solid $color-gray;
      border-radius: 4px;
      margin-top: 8px;
      padding: 12px 24px;
    }

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    margin-top: 32px;
  }
}

.react-datetime-picker {
  width: 100%;

  &__inputGroup {
    padding: 0 !important;
    width: 100%;

    &__input {
      font-family: Manrope !important;
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 12px !important;
      line-height: 27px !important;
      min-width: 10px;
      padding: 0 !important;

      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: transparent;
      background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat;
      background-position-x: 94%;
      background-position-y: 8px;
    }

    span {
      padding-bottom: 0 !important;
      padding-top: 0 !important;

      &:nth-of-type(3) {
        padding: 0 8px;
      }
    }
  }

  &__wrapper {
    border: 1px solid $color-gray !important;
    border-radius: 4px;
    margin-top: 8px;
    padding: 12px 24px;
  }
}