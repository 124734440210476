.footer {
  padding: 40px $side-margin-mobile 40px;

  @include breakpoint(s) {
    padding: 64px $side-margin-tablet 40px;
  }

  @include breakpoint(l) {
    padding: 80px $side-margin-desktop 40px;
  }

  &__column-title {
    margin-bottom: 8px;

    @include breakpoint(s) {
      margin-bottom: 16px;
    }
  }

  &__column {
    margin-bottom: 24px;
  }

  &__logo-media {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-bottom: 32px;
    position: relative;

    @include breakpoint(s) {
      align-items: center;
      flex-direction: row;
      margin-bottom: 32px;
    }

    @include breakpoint(m) {
      align-items: unset;
      flex-direction: column;
    }
  }

  &__logo {
    flex-shrink: 0;
    height: 64px;
    margin-bottom: 0;
    margin-right: 40px;
    position: relative;
    width: 64px;

    @include breakpoint(s) {
      height: 96px;
      margin-bottom: 0;
      margin-right: 40px;
      width: 96px;
    }

    @include breakpoint(m) {
      height: 96px;
      margin-bottom: 40px;
      width: 96px;
    }
  }

  &__social-media {
    height: 24px;
    margin: 4px 0;
    position: relative;
    width: 24px;

    &:not(:last-of-type) {
      margin-right: 24px;
    }

    &-links {
      display: flex;
      height: auto;
      position: relative;
      
      @include breakpoint(s) {
        width: 100%;
      }
    }
  }
}