.fullWidthImage {
  min-height: 100vh;
  position: relative;
  width: 100%;

  &__content {
    align-items: flex-start;
    color: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    min-height: 100vh;
    padding: 100px 0;
    z-index: 3;

    &-title,
    &-subtitle {
      margin-bottom: 32px;
    }
  }
}