.Modal {
  bottom: 0;
  left: 0;
  margin: auto;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;

  &--closeable {
    .Modal__overlay {
      cursor: alias;
    }
  }

  .Modal__overlay {
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    left: 0;
    position: absolute;
    opacity: 0.5;
    right: 0;
    top: 0;
    visibility: hidden;
    z-index: 0;
  }

  .Modal__sheet {
    left: 50%;
    max-height: 80vh;
    max-width: 400px;
    min-width: 30%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    visibility: hidden;
    width: 100%;
    z-index: 1;
  }

  .Modal__close {
    height: 14px;
    right: 24px;
    position: absolute;
    top: 24px;
    width: 14px;
    z-index: 1;
  }

  .Modal__scroll {
    overflow-y: auto;
  }

  .Modal__content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    position: relative;
  }

  &--open {
    pointer-events: auto;

    .Modal__overlay {
      opacity: 1;
      visibility: visible;
    }

    .Modal__sheet {
      opacity: 1;
      transform: translate3d(-50%, -50%, 0);
      visibility: visible;
    }
  }
}
