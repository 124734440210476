.TeamPage {
  padding-top: 96px;

  &__content {
    display: flex;
    flex-direction: column;

    @include breakpoint(xs) {
      flex-direction: row;
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    margin-right: 80px;
    max-width: 600px;
    width: 100%;
  }

  &__name {
    margin: 8px 0;
  }

  &__position {
    margin-bottom: 8px;
  }

  &__bio {
    margin-top: 8px;
    max-width: 500px;

    @include breakpoint(xs) {
      margin-top: 0;
    }
  }

  &__image {
    height: 60vh;
    position: relative;
    width: 100%;

    @include breakpoint(xs) {
      height: 40vh;
      width: 40vw;
    }

    @include breakpoint(s) {
      height: 50vh;
      max-height: 600px;
    }

    @include breakpoint(m) {
      height: 80vh;
      max-width: 600px;
    }
  }

  .Image {
    z-index: 0;
  }
}