.headlineAndLink {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    padding: 256px 0;
  }

  &__button {
    margin-top: 16px;
  }
}