.Header {
  align-items: center;
  display: flex;
  height: 80px;
  justify-content: space-between;
  left: 0;
  margin: auto;
  max-width: #{$grid-width}px;
  padding: 16px $side-margin-mobile 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 999;

  @include breakpoint(s) {
    padding: 16px $side-margin-tablet 0;
  }

  @include breakpoint(l) {
    padding: 16px $side-margin-desktop 0;
  }

  &__logo {
    height: 48px;
    position: relative;
    width: 48px;
  }

  &__links {
    align-items: center;
    display: none;

    @include breakpoint(s) {
      display: flex;
    }

    &--active {
      font-weight: bold;
    }

    a {
      font-family: Manrope;
      font-style: normal;
      font-size: 14px;
      line-height: 19px;
      text-decoration: none;

      @include breakpoint(m) {
        font-size: 16px;
        line-height: 22px;
      }

      &:visited {
        color: currentColor;
      }
    }
  }

  &__link {
    &:not(:last-child) {
      margin-right: 48px;
    }
  }

  &__hamburger {
    &-line {
      background-color: currentColor;
      display: block;
      height: 2px;
      margin: 6px 0;
      transition: all 0.3s ease-in-out;
      width: 24px;
    }

    @include breakpoint(s) {
      display: none;
    }
  }
  

  &--menu {
    align-items: flex-start;
    background: $color-white;
    justify-content: center;
    height: 100vh;

    a {
      color: $color-black;

      &:visited {
        color: currentColor;
      }
    }

    svg {
      path {
        stroke: $color-black;
      }
    }

    .Header__logo {
      left: $side-margin-mobile;
      position: absolute;
      top: 24px;
    }

    .Header__hamburger {
      right: $side-margin-mobile;
      position: absolute;
      top: 40px;

      &-line {
        background: $color-black;

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(1){
          transform: translateY(0.5px) rotate(45deg);
        }

        &:nth-child(3){
          transform: translateY(-15px) rotate(-45deg);
        }
      }
    }

    .Header__links {
      display: flex;
      flex-direction: column;
      margin-top: 180px;
    }

    .Header__link {  
      margin-bottom: 24px;
      margin-right: 0;

      a {
        font-size: 20px;
      }
    }

    .Header__button {
      margin-top: 32px;
    }
  }
}