.JobPage {
  padding: 200px 0 0;

  &__tags {
    margin-bottom: 40px;
    margin-top: 4px;
  }

  &__tag {
    color: $color-darkest-gray;
    font-weight: bold;

    &:not(:last-of-type) {
      &:after {
        background: $color-gray;
        border-radius: 8px;
        color: $color-gray;
        content: " ";
        display: inline-block;
        height: 4px;
        margin: auto 8px;
        vertical-align: middle;
        width: 4px;
      }
    }
  }
}