.FullWidthVideo {
  &__container {
    margin: auto;
    min-height: 100vh;
    position: relative;

    @include breakpoint(l) {
      margin-left: $side-margin-tablet;
      margin-right: $side-margin-tablet;
    }
  } 

  &__video {
    left: 50%;
    max-width: #{$grid-width}px;
    position: absolute;
    right: 0;
    transform: translate(-50%, -50%);
    top: 50%;
    width: 100%;

    @include breakpoint(l) {
      width: calc(100% - (#{$side-margin-tablet} * 2));
    }
  }
}