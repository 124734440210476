.LogoDisplay {
  align-content: center;
  background-color: #c9e3f5;
  padding: 200px 0;

  &__container {
    display: flex;
    flex-direction: column;
    z-index: 2;
  }

  &__title {
    text-align: center;
    margin-bottom: 64px;

    @include breakpoint(s) {
      margin-bottom: 96px;
    }
  }

  &__logos {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__logo {
    height: 200px;
    margin: 0 40px;
    min-width: 200px;
    position: relative;
    width: 200px;
  }
}
