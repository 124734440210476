.Faq {
  min-height: 100vh;
  padding: 160px 0;

  &__container {
    align-self: center;
  }

  &__title {
    margin-bottom: 64px;
  }

  &__accordion {
    &-item {
      padding: 12px 0px;
      width: 100%;

      @include breakpoint(s) {
        padding: 12px 16px;
      }

      svg {
        margin-left: 16px;
        width: 18px;
      }

      &-button {
        align-items: center;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        padding: 18px;
        width: 100%;
        text-align: left;
        border: none;
      }

      &-panel {
        animation: fadein 0.35s ease-in;
        padding: 16px;
      }
    } 
  }
}

@keyframes fadein {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}
