.TextHero {
  min-height: 600px;
  padding: 200px 0;

  @include breakpoint(s) {
    padding: 512px 0 160px;
  }

  &__content {
    z-index: 3;
  }

  &__heading {
    &--white {
      color: $color-white;
    }
  }

  &__button {
    margin-top: 24px;
  }

  &__children {
    display: none;
    left: 0;
    margin: auto;
    max-width: 400px;
    right: 0;

    @include breakpoint(s) {
      bottom: -80px;
      display: unset;
      left: unset;
      margin: unset;
      right: 40px;
      top: unset !important;
    }

    @include breakpoint(l) {
      right: 200px;
    }
  }
}