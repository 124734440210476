.Team {
  margin-top: 96px;

  .Image {
    z-index: 0;
  }

  &__employees {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -32px;
  }

  &__title {
    margin-bottom: 32px;
  }

  &__employee {
    display: flex;
    flex-direction: column;
    padding: 0 32px 64px 32px;
    width: 100%;

    &-image {
      height: 60vh;
      position: relative;
      width: 100%;
    }

    @include breakpoint(xs) {
      width: calc(100% / 2);

      &-image {
        height: 60vw;
      }
    }

    @include breakpoint(s) {
      &-image {
        height: 50vw;
      }
    }

    @include breakpoint(m) {
      width: calc(100% / 3);

      &-image {
        height: 30vw;
      }
    }

    &-name {
      margin-top: 8px;
    }

    &-position {
      margin: 8px 0;
    }
  }

  &__social-media {
    height: 24px;
    margin-bottom: 16px;
    position: relative;
    width: 24px;

    &:not(:last-of-type) {
      margin-right: 24px;
    }

    &-links {
      display: flex;
      height: auto;
      position: relative;
      
      @include breakpoint(s) {
        width: 100%;
      }
    }
  }
}