.representativeTestimonials {
  background: $color-black;
  padding: 100px 0;

  @include breakpoint(s) {
    padding: 200px 0;
  }

  &__title {
    margin-bottom: 40px;
  }

  &__content {
    margin-bottom: 64px;
  }

  &__button {
    margin: auto;
  }
}