.Loading {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;

  &__spinner {
    animation: spin 1s linear infinite;
    border-radius: 50%;
    border-top: 5px solid $color-blue;
    border-right: 5px solid transparent;
    height: 100px;
    width: 100px;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}