.CustomerForm {
  border: 1px solid #EDEDED;
  box-sizing: border-box;
  box-shadow: $box-shadow-card;
  border-radius: 16px;
  padding: 48px 40px;

  &__heading {
    margin-bottom: 32px;
    text-align: center;
  }

  &__label {
    display: flex;
    flex-direction: column;

    input {
      border: 1px solid $color-gray;
      border-radius: 4px;
      margin-top: 8px;
      padding: 12px 24px;
    }

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }

  &__fieldset {
    display: flex;
    flex-direction: column;
  }

  &__button {
    display: flex;
    justify-content: center;
    margin-top: 32px;
  }
}