.Image {
  position: relative;
  width: 100%;

  &--overlay {
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  &--cover,
  &--contain {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -2;
  }

  &__lqip,
  &__img {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;

    &--cover {
      background-position: center;
      object-fit: cover;
    }

    &--contain {
      background-position: center;
      object-fit: contain;
    }
  }

  &__img {
    opacity: 0;

    &--loaded {
      opacity: 1;
    }
  }

  &__lqip {
    &--loaded {
      opacity: 0;
    }
  }
}
