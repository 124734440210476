.IconList {
  align-content: center;
  min-height: 100vh;
  padding: 200px 0;

  &__rows {
    margin-top: 56px;

    @include breakpoint(s) {
      margin-left: 32px;
    }
  }

  &__row {
    align-items: center;
    display: flex;

    &-icon {
      flex-shrink: 0;
      height: 64px;
      margin-right: 32px;
      position: relative;
      width: 64px;

      @include breakpoint(s) {
        height: 100px;
        width: 100px;
      }
    }

    &-text {
      max-width: 400px;
    }
  }
}