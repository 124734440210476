.TextBlock {
  &__heading-2 {
    margin-top: 40px;
    margin-bottom: 12px;
  }

  &__heading-3 {
    margin-top: 32px;
    margin-bottom: 12px;
  }
}