$grid-width: 1920;
$grid-columns: 19;
$side-margin-mobile: 24px;
$side-margin-tablet: 40px;
$side-margin-desktop: auto;

$gutter-width-mobile: 10px;
$gutter-width-tablet: 12px;
$gutter-width-desktop: 16px;

// For Chrome 80: https://twitter.com/paddykontschak/status/1227510124874518528?s=20
$fr: minmax(0, 1fr);

// Base grid settings
@mixin grid-settings {
  display: grid;
  grid-auto-flow: row;
  grid-column-gap: $gutter-width-mobile;
  grid-template-columns: repeat($grid-columns, $fr);
  margin: 0 $side-margin-mobile;

  @include breakpoint(s) {
    grid-column-gap: $gutter-width-tablet;
    margin: 0 $side-margin-tablet;
  }
  @include breakpoint(xl) {
    grid-column-gap: $gutter-width-desktop;
    margin: 0 auto;
    max-width: #{$grid-width}px;
  }
}

// This class sets css grid styles to an element across all breakpoints
.grid-parent {
  @include grid-settings;

  // If you need to nest grids, we need to clear out the outside gutter
  .grid-parent,
  .grid-parent-xxs,
  .grid-parent-xs,
  .grid-parent-s,
  .grid-parent-l {
    margin-left: 0;
    margin-right: 0;
  }
}

// These mixins grid styles for specific breakpoints only
@include breakpoint(s) {
  .grid-parent-s {
    @include grid-settings;
    margin: 0 $side-margin-tablet;
  }
}

@include breakpoint(l) {
  .grid-parent-l {
    @include grid-settings;
  }
}

// Basic grid item
.grid-item {
  grid-column-end: auto;
  grid-column-start: auto;

  &.-center-vertical {
    align-self: center;
  }

  .-no-wrap & {
    grid-row-start: 1;
  }
}

// Use to set how many columns an item should span.
// .grid-span-4 will make the grid item span four columns wide.
@for $index from 1 through $grid-columns {
  .grid-span-#{$index} {
    grid-column-end: span #{$index};
  }
}

// Use to set what column to start an item at (1 indexed).
// .grid-start-4 will start the content at column 4.
@for $index from 1 through $grid-columns {
  .grid-start-#{$index} {
    grid-column-start: #{$index};
  }
}

@include breakpoint(xxs) {
  @for $index from 1 through $grid-columns {
    .grid-span-xxs-#{$index} {
      grid-column-end: span #{$index};
    }
  }

  .grid-start-xxs-0 {
    grid-column-start: auto;
  }

  @for $index from 1 through $grid-columns {
    .grid-start-xxs-#{$index} {
      grid-column-start: #{$index};
    }
  }
}

@include breakpoint(xxs) {
  @for $index from 1 through $grid-columns {
    .grid-span-xxs-#{$index} {
      grid-column-end: span #{$index};
    }
  }

  .grid-start-xs-0 {
    grid-column-start: auto;
  }

  @for $index from 1 through $grid-columns {
    .grid-start-xs-#{$index} {
      grid-column-start: #{$index};
    }
  }
}

// Breakpoint-specific column spans and start points
@include breakpoint(s) {
  @for $index from 1 through $grid-columns {
    .grid-span-s-#{$index} {
      grid-column-end: span #{$index};
    }
  }

  .grid-start-s-0 {
    grid-column-start: auto;
  }

  @for $index from 1 through $grid-columns {
    .grid-start-s-#{$index} {
      grid-column-start: #{$index};
    }
  }
}

// Breakpoint-specific column spans and start points

@include breakpoint(m) {
  @for $index from 1 through $grid-columns {
    .grid-span-m-#{$index} {
      grid-column-end: span #{$index};
    }
  }

  .grid-start-m-0 {
    grid-column-start: auto;
  }

  @for $index from 1 through $grid-columns {
    .grid-start-m-#{$index} {
      grid-column-start: #{$index};
    }
  }
}

@include breakpoint(l) {
  @for $index from 1 through $grid-columns {
    .grid-span-l-#{$index} {
      grid-column-end: span #{$index};
    }
  }

  .grid-start-l-0 {
    grid-column-start: auto;
  }

  @for $index from 1 through $grid-columns {
    .grid-start-l-#{$index} {
      grid-column-start: #{$index};
    }
  }
}

@include breakpoint(xl) {
  @for $index from 1 through $grid-columns {
    .grid-span-xl-#{$index} {
      grid-column-end: span #{$index};
    }
  }

  .grid-start-xl-0 {
    grid-column-start: auto;
  }

  @for $index from 1 through $grid-columns {
    .grid-start-xl-#{$index} {
      grid-column-start: #{$index};
    }
  }
}

// Pulls grid container out of the surrounding gutter to go full bleed
.grid-no-offset {
  margin-left: 0;
  margin-right: 0;

  @include breakpoint(xl) {
    margin-left: auto;
    margin-right: auto;
  }

  .grid-parent & {
    margin-left: -($side-margin-mobile);
    margin-right: -($side-margin-mobile);

    @include breakpoint(s) {
      margin-left: -($side-margin-tablet);
      margin-right: -($side-margin-tablet);
    }
  }
}

.grid-no-offset-mobile {
  margin-left: 0;
  margin-right: 0;

  @include breakpoint(s) {
    margin: 0 $side-margin-tablet;
  }
  @include breakpoint(xl) {
    margin: 0 auto;
    max-width: #{$grid-width}px;
  }
}
