@mixin breakpoint($point) {
  @if $point == xxs {
    @media #{'(min-width: 400px)'} {
      @content;
    }
  } @else if $point == xs {
    @media #{'(min-width: 600px)'} {
      @content;
    }
  } @else if $point == s {
    @media #{'(min-width: 768px)'} {
      @content;
    }
  } @else if $point == m {
    @media #{'(min-width: 1024px)'} {
      @content;
    }
  } @else if $point == l {
    @media #{'(min-width: 1440px)'} {
      @content;
    }
  } @else if $point == xl {
    @media #{'(min-width: 1920px)'} {
      @content;
    }
  }
}

/**
 * These values will not show up in content, but can be
 * queried by JavaScript to know which breakpoint is active.
 * This way we can keep our JS and CSS in sync
 */
body::after {
  content: "";
  display: none;

  @include breakpoint(xxs) {
    content: "xxs";
  }

  @include breakpoint(xs) {
    content: "xs";
  }

  @include breakpoint(s) {
    content: "s";
  }

  @include breakpoint(m) {
    content: "m";
  }

  @include breakpoint(l) {
    content: "l";
  }

  @include breakpoint(xl) {
    content: "xl";
  }
}
