.EssayBlock {
  min-height: 100vh;
  padding: 100px 0;

  @include breakpoint(s) {
    padding: 200px 0;
  }

  &__container {
    align-self: center;
  }

  &__title {
    margin-bottom: 32px;

    @include breakpoint(m) {
      margin-bottom: 56px;
    }
  }
}