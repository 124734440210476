.Testimonial {
  &__text {
    &-heading {
      color: $color-dark-gray;
      margin-bottom: 8px;
      margin-top: 16px;
    }

    &-story {
      color: $color-dark-gray;
    }

    &-job-position {
      color: $color-dark-gray;
    }

    &-job {
      margin-bottom: 8px;
    }

    &-job-salary {
      color: $color-white;
      font-weight: 800;

      &::after {
        background: $color-dark-gray;
        border-radius: 4px;
        content: " ";
        display: inline-block;
        height: 4px;
        margin: 0 8px;
        vertical-align: middle;
        width: 4px;
      }
    }
  }
}